@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

p{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

a{
  text-decoration: none;
  font-family: inherit;
  color: inherit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: start;
          justify-content: start;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.home-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.home-paw-pic{
  width: 10%;
  max-width: 75px;
  padding-bottom: 20px;
  margin-right: 32%;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-transform:rotate(15deg);
          transform:rotate(15deg);
}

.home-smaller-paw-pic{
  width: 7%;
  max-width: 55px;
  padding-bottom: 20px;
  margin-right: 35%;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-transform:rotate(-15deg);
          transform:rotate(-15deg);
}

.home-luna-pic{
  width: 18%;
  min-width: 350px;
  margin-top: -100px;
  border-radius: 25px;
}

.home-main-text{
  margin: 20px;
}

.home-main-text p{
  margin: 10px;
}

.home-contact-info{
  margin: 20px;
}

.home-contact-info p{
  margin: 10px;
}

.home-contact-info a{
  transition-duration: 0.7s;
}

.home-contact-info a:hover{
  color: #66CCCC;
}

.home-social-links{
  margin-top: 10px;
}

.home-social-links a{
  padding: 10px;
  transition-duration: 0.7s;
}

.home-social-links a:hover{
  color: #66CCCC;
}

@media only screen and (max-width: 1300px) {
  .home-paw-pic{
    width: 10%;
    max-width: 75px;
    padding-bottom: 20px;
    margin-right: 25%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(15deg);
            transform:rotate(15deg);
  }
  
  .home-smaller-paw-pic{
    width: 7%;
    max-width: 55px;
    padding-bottom: 20px;
    margin-right: 30%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }
}

@media only screen and (max-width: 1000px) {
  .home-paw-pic{
    width: 10%;
    max-width: 75px;
    padding-bottom: 20px;
    margin-right: 18%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(15deg);
            transform:rotate(15deg);
  }
  
  .home-smaller-paw-pic{
    width: 7%;
    max-width: 55px;
    padding-bottom: 20px;
    margin-right: 23%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }
}

@media only screen and (max-width: 730px) {
  .home-paw-pic{
    width: 10%;
    max-width: 75px;
    padding-bottom: 20px;
    margin-right: 14%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(15deg);
            transform:rotate(15deg);
  }
  
  .home-smaller-paw-pic{
    width: 7%;
    max-width: 55px;
    padding-bottom: 20px;
    margin-right: 16%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }
}
